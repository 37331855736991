import React, { useContext, useEffect, useState } from 'react'
import { useStaticQuery, graphql, navigate } from 'gatsby'
import Layout from '../../components/layout/layout.component'
import workshopStyles from './workshop.module.scss'
import Collections from '../../components/collections/collections.component'
import Search from '../../components/search/search.component'
import Img from 'gatsby-image'
import ThemeContext from '../../context/ThemeContext'
import Image from '../../components/image/image.component'
import { useSize } from '../../hooks/size.hook'

export default function Workshop() {
   const {
      allNodeArtwork: { edges },
      categories,
      background,
   } = useStaticQuery(graphql`
      query {
         allNodeArtwork(filter: { field_studio_wall: { eq: true } }) {
            edges {
               node {
                  id
                  field_medium
                  title
                  field_date
                  field_object_number
                  field_virtual_exhbition
                  field_primary_dimensions
                  field_studio_wall
                  field_has_background
                  field_media_quantity
                  field_image {
                     title
                  }

                  relationships {
                     workshopImage: field_image {
                        localFile {
                           childImageSharp {
                              fluid(maxHeight: 200, quality: 80) {
                                 src
                                 base64
                              }
                           }
                        }
                     }
                     field_category {
                        name
                     }
                  }
               }
            }
         }

         categories: allTaxonomyTermCategory(sort: { fields: name }) {
            nodes {
               id
               name
               relationships {
                  node__artwork {
                     field_virtual_exhbition
                  }
               }
            }
         }

         background: file(
            relativeDirectory: { eq: "studio" }
            base: { eq: "f4.jpg" }
         ) {
            childImageSharp {
               fixed(width: 1600, quality: 40) {
                  ...GatsbyImageSharpFixed
               }
            }
         }
      }
   `)

   const [gallery, setGallery] = useState([])
   const [isLoaded, setIsLoaded] = useState(false)
   const { isMobile } = useSize()
   const context = useContext(ThemeContext)

   const handleClick = go => {
      /*
       * Adding data to context api
       **/
      context.setGoBack(() => navigate('/studio/workshop'))
      context.setIsStudio(true)
      navigate(go)
   }

   const getRandomInt = max => {
      /*
       * Get Random num
       **/
      return Math.floor(Math.random() * Math.floor(max))
   }

   const findCategory = (edges, category) => {
      /*
       * Filter images per category
       **/
      const images = edges?.filter(edge => {
         return (
            edge?.node?.relationships?.workshopImage[0]?.localFile &&
            edge?.node?.relationships?.field_category?.name === category
         )
      })

      const random = getRandomInt(images.length || 0)

      return {
         images,
         random,
         total: images.length,
         selected: images[random],
         category,
      }
   }

   useEffect(() => {
      /*
       * Added estimated window height
       */
      setTimeout(() => {
         setIsLoaded(true)
      }, 200)
   }, [isMobile])

   useEffect(() => {
      /*
       * Filter stablished categories
       **/
      const collage = findCategory(edges, 'Collage') || []
      const paintings = findCategory(edges, 'Paintings') || []
      const drawings = findCategory(edges, 'Drawings') || []

      setGallery([paintings.selected, collage.selected, drawings.selected])
   }, [])

   return (
      <>
         <Layout hasBackground showFooter hideStudioIcon isMenuWhite>
            <div className={workshopStyles.container}>
               <div
                  className={`${workshopStyles.studio} ${
                     isLoaded ? workshopStyles.height : ''
                  }`}
               >
                  <div className={workshopStyles.wrapper}>
                     {gallery?.map((edge, key) => {
                        return (
                           <button
                              key={key}
                              onClick={() =>
                                 handleClick(
                                    `/artwork/${edge?.node?.field_object_number}`
                                 )
                              }
                           >
                              <Image
                                 className={`${
                                    key === 0
                                       ? workshopStyles?.picture1
                                       : key === 1
                                       ? workshopStyles?.picture2
                                       : workshopStyles?.picture3
                                 }`}
                                 src={
                                    edge?.node?.relationships?.workshopImage[0]
                                       ?.localFile?.childImageSharp?.fluid
                                 }
                                 alt=''
                                 background={null}
                              />
                           </button>
                        )
                     })}
                  </div>
                  <button
                     className={workshopStyles.art}
                     onClick={() => handleClick(`/artwork/${'SCU-092'}`)}
                  />
                  <Img
                     fixed={background?.childImageSharp?.fixed}
                     objectFit='cover'
                     objectPosition='50% 50%'
                     alt=''
                     style={{ width: '100%', height: '100%' }}
                     fadeIn
                  />
               </div>
               <h2 className={workshopStyles.description}>
                  Vicente Studio, Bridehampton New York 2000
               </h2>
            </div>
         </Layout>

         <div className={workshopStyles.bottomSection}>
            {!context.open && (
               <>
                  <Collections data={categories?.nodes} />
                  <Search data={edges} />
               </>
            )}
         </div>
      </>
   )
}
