import React from 'react'
import collectionsStyles from './collections.module.scss'
import { Link } from 'gatsby'

export default function Collections({ hasBackground = true, data, styles }) {
  const categories = data.filter(category => {
    const virtualExhibition = category.relationships.node__artwork?.filter(
      node => node.field_virtual_exhbition === true
    )
    //console.log('category name', category)
    return (
      category.name.toLowerCase() !== 'sculptures' &&
      category.relationships.node__artwork?.length > 0 &&
      virtualExhibition?.length > 0
    )
  })
  return (
    <div
      className={`${collectionsStyles.container} 
        ${hasBackground ? '' : collectionsStyles.light} 
        ${styles ? styles : ''} 
      `}
    >
      { /* <h1>{hasBackground ? 'Virtual Exhibitions' : 'Exhibitions'}:</h1>
      <ul>
        {categories.map(element => (
          <li key={element.id}>
            <Link
              to={`/virtual-exhibitions?category=${element.name.toLowerCase()}`}
            >
              {element.name}
            </Link>
          </li>
        ))}
      </ul> */}
    </div>
  )
}
